/* @flow */
/* Do not edit - Generated by iconify. See README.md for more details. */
/* eslint-disable max-len */
import type { ElementConfig as ReactElementConfig } from "react"

import BaseIcon from "./BaseIcon";

export default class SvgIconUndo extends BaseIcon {
    static defaultProps: ReactElementConfig<typeof BaseIcon> = {
        iconClass: "UndoIcon",
    }

    svgContent: string = `
        <svg class='${this.classNames}' width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"><mask id="mask0_1908_796" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="25"><rect y="0.5" width="24" height="24" fill="#D9D9D9"/></mask><g mask="url(#mask0_1908_796)"><path d="M14.1 19.5H7V17.5H14.1C15.15 17.5 16.0623 17.1667 16.837 16.5C17.6123 15.8333 18 15 18 14C18 13 17.6123 12.1667 16.837 11.5C16.0623 10.8333 15.15 10.5 14.1 10.5H7.8L10.4 13.1L9 14.5L4 9.5L9 4.5L10.4 5.9L7.8 8.5H14.1C15.7167 8.5 17.1043 9.025 18.263 10.075C19.421 11.125 20 12.4333 20 14C20 15.5667 19.421 16.875 18.263 17.925C17.1043 18.975 15.7167 19.5 14.1 19.5Z" fill="#363F44"/></g></svg>
    `;
}
