/* @flow */
/* Do not edit - Generated by iconify. See README.md for more details. */
/* eslint-disable max-len */
import type { ElementConfig as ReactElementConfig } from "react"

import BaseIcon from "./BaseIcon";

export default class SvgIconMyList extends BaseIcon {
    static defaultProps: ReactElementConfig<typeof BaseIcon> = {
        iconClass: "MyListIcon",
    }
    // class='${this.classNames}'

    svgContent: string = `
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg"><mask id="mask0_1908_790" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24"><rect x="0.8125" width="24" height="24" fill="#D9D9D9"/></mask><g mask="url(#mask0_1908_790)"><path d="M12.8125 5C13.0958 5 13.3335 4.904 13.5255 4.712C13.7168 4.52067 13.8125 4.28333 13.8125 4C13.8125 3.71667 13.7168 3.479 13.5255 3.287C13.3335 3.09567 13.0958 3 12.8125 3C12.5292 3 12.2918 3.09567 12.1005 3.287C11.9085 3.479 11.8125 3.71667 11.8125 4C11.8125 4.28333 11.9085 4.52067 12.1005 4.712C12.2918 4.904 12.5292 5 12.8125 5ZM5.8125 21C5.2625 21 4.7915 20.8043 4.3995 20.413C4.00817 20.021 3.8125 19.55 3.8125 19V5C3.8125 4.45 4.00817 3.979 4.3995 3.587C4.7915 3.19567 5.2625 3 5.8125 3H9.9875C10.1708 2.41667 10.5292 1.93733 11.0625 1.562C11.5958 1.18733 12.1792 1 12.8125 1C13.4792 1 14.0752 1.18733 14.6005 1.562C15.1252 1.93733 15.4792 2.41667 15.6625 3H19.8125C20.3625 3 20.8335 3.19567 21.2255 3.587C21.6168 3.979 21.8125 4.45 21.8125 5V19C21.8125 19.55 21.6168 20.021 21.2255 20.413C20.8335 20.8043 20.3625 21 19.8125 21H5.8125ZM5.8125 19H19.8125V5H17.8125V8H7.8125V5H5.8125V19Z" fill="#363F44"/></g></svg>
    `;
}
